<template>
  <div class="work-page ca-watch-me">

    <div class="container-block">

      	<!-- Header -->
      	<section class="header">
          <img src="/static/images/WM_1.png" class="mobile-fullwidth"/>
      		<div class="meta" style="margin-top: -4%;">
      			<p class="client">CRICKET AU</p>
      			<a class="project">#WATCHME</a>
      		</div>
      		<div class="copy container">
      			<div class="col-1">
      				<h1>Drive awareness of women’s cricket.</h1>
      			</div>
      			<div class="col-2">
      				<p>Watch me smash boundaries. Watch me own this game. A campaign that challenges the misconception that women’s cricket doesn’t live up to the men’s game.</p>
      			</div>
      		</div>
      	</section>

        <div class="spacer-lg"></div>

        <section>
          <div class="two-col">
            <div class="col" style="width: 25%; margin-right: -10%; z-index: 2;">
              <img src="/static/images/WM_2.png"/>
            </div>
            <div class="col" style="width: 65%; margin-right: -20%;">
              <img src="/static/images/WM_3.png"/>
            </div>
          </div>
        </section>

    </div>

    <!-- <div class="spacer-lg"></div> -->

    <div style="padding:56.25% 0 0 0;position:relative;">
      <!-- <div 
      id="poster-image"
      class="poster-image"
      style="background: url('/static/images/WM_4.png') center center; background-size: cover"
      ></div> -->
      <iframe 
        class="video"
        src="https://player.vimeo.com/video/363470533?title=0&byline=0&portrait=0" 
        style="position:absolute;top:0;left:0;width:100%;height:100%;" 
        frameborder="0" 
        allow="autoplay; fullscreen" 
        allowfullscreen>
      </iframe>
    </div>

    <!-- <div class="spacer-lg"></div> -->

    <div class="container-block">

      <div class="two-col" style="justify-content: space-between;">
        <div class="col" style="width: 35%;">

          <!-- Mobile video mockup -->
          <div class="mobile-mockup-wrapper">
            <div class="mobile-mockup-container" style="width: 100%">
              <video 
                width="100%" 
                height="auto" 
                src="/static/videos/WM_4_VIDEO.mp4"  
                frameborder="0" 
                playsinline
                loop
                controls
                poster="/static/images/WM_social_poster.png"
                ></video>
            </div>
          </div>

        </div>
        <div class="col" style="width: 56%; display: flex; flex-direction: column; justify-content: flex-start; align-items: center;">
          <img style="width: 90%; margin-bottom: -80%; z-index: 3; position: relative;" src="/static/images/WM_6.png"/>
        </div>
      </div>

      <div class="spacer-lg"></div>
      <div class="spacer-lg"></div>

      <img src="/static/images/WM_7.jpg"/>

      <div class="spacer-lg"></div>

      <div class="standout-copy" style="text-align: left; width: 100%;">
        An answer to doubt. A rally cry to support the national team.
      </div>

      <div class="spacer-lg"></div>

      <img src="/static/images/WM_8.jpg"/>

      <div class="spacer-lg"></div>

      <div class="two-col" style="justify-content: space-between;">
        <div class="col" style="width: 49%;">
          <img style="width: 100%; position: relative;" src="/static/images/WM_9.jpg"/>
        </div>
        <div class="col" style="width: 47%; display: flex; align-items: center; justify-content: center;">
          <img style="width: 80%; margin-top: -120%; z-index: 3; position: relative;" src="/static/images/WM_10.jpg"/>
        </div>
      </div>

      <div class="spacer-lg"></div>

      <img src="/static/images/WM_11.jpg" class="mobile-fullwidth"/>

      <div class="footer">
        <div class="prev">
          <router-link to="/nike-matildas">
            <img src="/static/images/prev.svg"/>
          </router-link>
        </div>
        <div class="footer-contact">
          <a href="mailto:grant@bloodutd.com,david@bloodutd.com">Drop us a line</a>
          <span>&copy; {{date}} by Blood UTD</span>
        </div>
        <div class="next">
          <router-link to="/nike-mercurial">
            <img src="/static/images/next.svg"/>
          </router-link>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'ca-watch-me',
  data() {
    return {
      date: new Date().getFullYear(),
    }
  },
  mounted() {
    // $(".video").hover(function(event) {
    //     if(event.type === "mouseenter") {
    //         $(this).attr("controls", "");
    //     } else if(event.type === "mouseleave") {
    //         $(this).removeAttr("controls");
    //     }
    // });
    // $('.video').click(function(el) {
    //     this.paused ? this.play() : this.pause();
    // });

    // document.getElementById("poster-image").onclick = function() { 
    //     $(".poster-image").hide();
    //     $(".video")[0].src += "&autoplay=1";
    //  };

  }
}
</script>

<style lang="scss" scoped>

.ca-watch-me {

  .mobile-mockup-container video {
      box-sizing: border-box;
      background: url(/static/images/mobile-video-placeholder.png) center center no-repeat;
      background-size: contain;
      padding: 10.9% 16% 18.8%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: default;
  }

}

</style>
